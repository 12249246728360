import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logo-bje.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import Specs from "../../../components/specs"


const DreiDLiveViewApp = function (props, ref) {

    const data = useStaticQuery(graphql`
        query DreiDLiveViewAppQuery {
            heropic: file(relativePath: { eq: "images/projekte/bje-3d-liveview/thumbleiste/bje-3dliveview-02.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/bje-3d-liveview/thumbleiste/bje-3dliveview-02.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/bje-3d-liveview/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "App"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="3D LiveView App"
                description="Q:marketing realisiert Augmented Reality App für Busch-Jaeger und ABB."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" bgCol="" bgVideo="uwkaM2tM4yQ"  posterImage={data.heropic.childImageSharp.gatsbyImageData}>                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>3D LiveView App</h1>
                        <p className="introtext">Die 3D LiveView App von Busch-Jaeger macht Produkte schon erlebbar, bevor die installiert sind.</p>
                        <AnchorLink to="/projekte/virtuelle-produktpraesentationen/3d-liveview-app/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>                                                              
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>     

            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Die ganze Vielfalt des Produktportfolios von Busch-Jaeger virtuell erleben.",
                        "Sämtliche Produkte können über die 3D-App realitätsnah an die entsprechenden Stellen in Haus und Wohnung projiziert werden.",
                        "Kombination sämtlicher Design- und Farbvarianten.",
                        "Virtuelle Projektion durch Augmented Reality.",
                        "Integrierte Fachhändlersuche zur Kontaktaufnahme."
                    ]}
                />
            </ListenModule>
                  
            <Specs data={specsData} />

            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default DreiDLiveViewApp